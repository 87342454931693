<template lang="pug">
b-container
  Alert
  b-row.mt-5.exit_content
    b-button.exit(variant='primary', @click='exit') Salir
  b-row.mt-5
    b-col
      b-alert(variant='info', show)
        p.m-0 NOTA
        p.m-0 * Los archivos pueden ser CSV o TSV
        p.m-0 * En el caso de ser CSV deben de estar separados por punto y coma " ; "
        p.m-0 * Cada fila del archivo debe de terminar con un pipe " | "
        p.m-0 * Para identificar los saltos de linea en la descripcion colocar antidiagonal " \ "
        p.m-0 * Procura que el nombre de las imagenes no tengan espacios en blanco ni diagonales " / "
      p.w-100.text-center CSV
      b-form-file(@change='convertCSVtoJSON', accept='text/tab-separated-values, text/csv', :state='csvIsTrue', placeholder='Selecciona el archivo csv...')
      p.w-100.text-center.text-danger.mt-3 {{ csvError }}

  b-row.mt-5
    b-col
      p.w-100.text-center IMG
      b-form-file(@change='listImages', multiple, accept='image/*', :state='imagesIsTrue', placeholder='Selecciona el directorio de imágenes...')
      p.w-100.text-center.text-danger.mt-3(v-html='imagesError')

  .content_table.mt-5(:class='{ content_table_full: csvIsTrue }')
    b-table.w-100(striped, hover, :fields='fields', :items='items')

  b-row.mt-5.mb-5
    b-button.w-100(variant='primary', @click='send') Enviar
</template>

<script>
import { mapActions } from 'vuex'
import Alert from '../components/Alert'

export default {
  name: 'Admin',
  components: {
    Alert,
  },
  data() {
    return {
      csv: null,
      csvIsTrue: false,
      csvError: null,
      images: null,
      imagesIsTrue: false,
      imagesError: null,
      items: [],
      fields: [
        {
          key: 'index',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'sku',
          label: 'SKU PRODUCT',
          sortable: true,
        },
        {
          key: 'brand',
          label: 'MARCA',
          sortable: true,
        },
        {
          key: 'category',
          label: 'CATEGORÍA',
          sortable: true,
        },
        {
          key: 'subcategory',
          label: 'SUBCATEGORÍA',
          sortable: true,
        },
        {
          key: 'name',
          label: 'NOMRE DEL PRODUCTO',
          sortable: true,
        },
        {
          key: 'description',
          label: 'DESCRIPCION',
          sortable: true,
        },
        {
          key: 'image',
          label: 'NOMBRE DE LA IMAGEN',
          sortable: true,
        },
        {
          key: 'tlx',
          label: 'TLX',
          sortable: true,
        },
      ],
    }
  },
  methods: {
    ...mapActions(['saveFiles', 'removeAdmin']),
    convertCSVtoJSON(event) {
      try {
        let reader = new FileReader()
        reader.readAsText(event.target.files[0])
        reader.onload = () => {
          this.parseCSV(reader.result, event.target.files[0].type)
        }
      } catch (error) {
        this.csvIsTrue = false
        this.csvError = 'Ocurrio un error al leer el archivo. Intente nuevamente'
      }
    },
    parseCSV(csv, type) {
      try {
        let lines = csv.split('|')
        let result = []

        if (type === 'text/csv') {
          lines.map((element) => result.push(Object.assign({}, element.split(';'))))
          result = result
            .filter((row) => row['0'])
            .map((row) => ({
              index: Number(row['0'].trim()),
              sku: row['1'].trim(),
              brand: row['2'].trim(),
              category: row['3'].trim(),
              subcategory: row['4'].trim(),
              name: row['5'].trim(),
              description: row['6'].trim(),
              image: row['7'].trim(),
              tlx: Number(row['8'].trim()),
            }))

          result.splice(0, 1)
        } else {
          lines.map((element) => result.push(Object.assign({}, element.split('\t'))))
          result = result
            .filter((row) => row['0'])
            .map((row) => ({
              index: Number(row['0'].trim()),
              sku: row['1'].trim(),
              brand: row['2'].trim(),
              category: row['3'].trim(),
              subcategory: row['4'].trim(),
              name: row['5'].trim(),
              description: row['6'].trim(),
              image: row['7'].trim(),
              tlx: Number(row['8'].trim()),
            }))

          result.splice(0, 1)
        }

        this.csv = result
        this.items = result
        this.csvIsTrue = true
        this.csvError = null
      } catch (error) {
        this.csvIsTrue = false
        this.csvError = 'Ocurrio un error al leer el archivo. Intente nuevamente'
      }
    },
    listImages(event) {
      let fails = Object.values(event.target.files).filter((image) => !image.type.includes('image/'))
      let formData = new FormData()

      if (fails.length === 0) {
        this.imagesIsTrue = true
        this.imagesError = null
      } else {
        let names = fails.map((file) => file.name).join(',')
        this.imagesError = 'Estos archivos no son imagenes, por lo que no se tomaran en cuanta: <br/>' + names + '<br/>'
        this.imagesIsTrue = true
      }

      Object.values(event.target.files)
        .filter((image) => image.type.includes('image/'))
        .map((image) => formData.append('file[]', image))

      this.images = formData
    },
    async send() {
      if (this.csvIsTrue || this.imagesIsTrue) {
        let response = await this.saveFiles({ csv: this.csv, images: this.images })
        console.log("🚀 ~ file: Admin.vue ~ line 187 ~ send ~ images", this.images)

        if (response === 1) {
          this.$alert.$emit('alert', {
            type: 'success',
            message: 'Archivo CSV subido',
          })
        }

        if (response === 2) {
          this.$alert.$emit('alert', {
            type: 'success',
            message: 'Imagenes subidas',
          })
        }

        if (response === 3) {
          this.$alert.$emit('alert', {
            type: 'success',
            message: 'Archivos subidos',
          })
        }

        if (response === 0) {
          this.$alert.$emit('alert', {
            type: 'Danger',
            message: 'No se pudo subir ningún archivo',
          })
        }
      } else {
        this.$alert.$emit('alert', {
          type: 'warning',
          message: 'No has seleccionado ningun archivo',
        })
      }
    },
    exit() {
      this.removeAdmin()
      this.$router.push({ name: 'Login' })
    },
  },
}
</script>

<style lang="scss" scoped>
.content_table {
  width: 100%;
  height: fit-content;
}

.content_table_full {
  height: 50vh;
  overflow: auto;
}

.exit_content {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.exit {
  width: 120px;
}
</style>